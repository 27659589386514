import { useEffectOnce } from 'react-use'
import { useRouter } from 'next/router'
import useAuth from '@hooks/use-auth'

const Index = () => {
  const router = useRouter()
  const { token } = useAuth()

  useEffectOnce(() => {
    token ? router.push('/Landing') : router.push('/Bulletins')
  })

  return <div />
}

export default Index
